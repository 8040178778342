import { useState, useEffect, useCallback, useRef } from "react";
import { fetchModels } from "../api/modelsApi";
import { useAuth } from "../auth/authContext";
import debounce from "lodash/debounce";

const useModels = (initialModelId) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessToken, logout, isInitialized } = useAuth();
  const getModelsRef = useRef(null);

  const handle401Error = useCallback(async () => {
    if (!isInitialized) return;
    try {
      await getAccessToken();
    } catch (silentError) {
      console.error("Silent token acquisition failed:", silentError);
      await logout();
    }
  }, [getAccessToken, logout, isInitialized]);

  const getModels = useCallback(async () => {
    if (!isInitialized) return;
    setLoading(true);
    try {
      const token = await getAccessToken();
      if (!token) {
        await handle401Error();
        return;
      }
      const data = await fetchModels(token);
      setModels(data);
      console.log("Fetched Models:", data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await handle401Error();
      } else {
        console.error("Failed to fetch models:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [getAccessToken, handle401Error, isInitialized]);

  getModelsRef.current = getModels;

  useEffect(() => {
    const debouncedGetModels = debounce(() => {
      getModelsRef.current();
    }, 300);

    debouncedGetModels();
    return () => {
      debouncedGetModels.cancel();
    };
  }, []);

  return {
    models,
    loading,
    getModels,
  };
};

export default useModels;
