import React, { useEffect, useState } from "react";
import { Container, ListGroup } from "reactstrap";
import ContextListItem from "./ContextListItem";
import "./ContextList.css";
import SearchBar from "../searchbar/SearchBar";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import { useTheme } from "../../theme/theme";

const ContextList = ({
  contexts,
  onSelectContext,
  loading,
  selectedContext,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContexts, setFilteredContexts] = useState([]);
  const { theme } = useTheme();

  useEffect(() => {
    // Filtrerer kontekster basert på søketerm
    const result = contexts.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.description &&
          item.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredContexts(result);
  }, [searchTerm, contexts]);

  const handleContextSelect = (context) => {
    onSelectContext(context); // Oppdaterer valgt context i state, men setter den ikke i localStorage
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <LoadingOverlay size="small" isLoading={loading} />
      </Container>
    );
  }

  return (
    <Container
      className="context-list"
      style={{
        backgroundColor:
          theme === "light"
            ? "var(--background-color)"
            : "var(--background-color-dark)",
      }}
    >
      <section
        className="p-2 mb-3 mt-3 position-sticky fixed-top"
        style={{
          background:
            theme === "light"
              ? "var(--background-color)"
              : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </section>

      {filteredContexts.length === 0 ? (
        <Container className="text-center mt-5">
          <p>No contexts available</p>
        </Container>
      ) : (
        <ListGroup>
          {filteredContexts.map((item, index) => (
            <ContextListItem
              key={index}
              context={item}
              onSelectContext={handleContextSelect}
              isSelected={selectedContext?.id === item.id} // Markerer valgt context uten å lagre i localStorage
            />
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default ContextList;
