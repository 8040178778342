import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardText,
} from "reactstrap";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "./ContextInfo.css";
import {SecondaryButton } from "../buttons/Button";
import { useTheme } from "../../theme/theme";

const ContextInfo = ({ context, onEdit, onContextSelect }) => {
  const { t } = useTranslation();
  const selectedContextId = localStorage.getItem("selectedContext");
  const { theme } = useTheme();

  // Funksjon for å velge kontekst og lagre i localStorage
  const handleContextSelect = () => {
    localStorage.setItem("selectedContext", context.id);
    if (onContextSelect) {
      onContextSelect(context.id); // Oppdaterer om nødvendig andre komponenter
    }
    // Oppdater siden etter at en ny kontekst er valgt
    window.location.reload();
  };

  return (
    <Container className="context-info">
      <Card
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--card-background-color)"
              : "var(--card-background-color-dark)",
          color: theme === "light" ? "var(--dark)" : "var(--light)",
        }}
      >
        <CardBody>
          <CardTitle tag="h1" className="text-center mb-5">
            {context.name}
          </CardTitle>
          <Row>
            <Col>
              <ContextInfoItem
                label={t("description")}
                context={context.description}
              />
            </Col>
          </Row>
          <div className="button-container d-flex align-items-center">
            {selectedContextId === context.id ? (
              <p className="mb-0 me-3 selected-context-text">
                {t("this_context_is_selected")}
              </p>
            ) : (
              <SecondaryButton
                label={t("select_context")}
                onClick={handleContextSelect}
                className="me-3"
                textColor="blue"
                borderColor="blue"
              />
            )}
            <SecondaryButton label={t("edit")} icon={FaEdit} onClick={onEdit} />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

const ContextInfoItem = ({ label, context }) => {
  const { theme } = useTheme();
  return (
    <div className="p-3">
      <h5
        style={{
          color: theme === "light" ? "var(--primary)" : "var(--primary-dark)",
        }}
      >
        {label}:
      </h5>
      <CardText>{context}</CardText>
    </div>
  );
};

export default ContextInfo;
