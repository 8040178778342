import axios from "axios";

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/data/content`;

const fetchData = async (url, accessToken, method = "get", data = null) => {
  try {
    const contextId = localStorage.getItem("selectedContext");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Context-Id": contextId || "", // Legger til Context-Id fra localStorage
    };
    const config = {
      method,
      url,
      headers,
      data,
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

export const fetchContent = async (accessToken, page = 1, pageSize = 10) => {
  const url = `${API_URL}?page=${page}&pageSize=${pageSize}`;
  return await fetchData(url, accessToken);
};

export const createContent = async (newContent, accessToken) => {
  return await fetchData(API_URL, accessToken, "post", newContent);
};

export const updateContent = async (id, updatedContent, accessToken) => {
  return await fetchData(
    `${API_URL}/${id}`,
    accessToken,
    "patch",
    updatedContent
  );
};

export const deleteContent = async (id, accessToken) => {
  return await fetchData(`${API_URL}/${id}`, accessToken, "delete");
};
