import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "../theme/theme";
import PrimaryButton from "../components/buttons/Button";
import { Container, Row } from "reactstrap";

const DEFAULT_PINECONE_PHOTO = "/pinecone-big.png";

const LoggedOutHome = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Container fluid className="home-page text-center p-2 p-md-5">
      <Row className="text-center align-self-center m-1 m-md-2 pb-md-3">
        <div>
          <img
            src={DEFAULT_PINECONE_PHOTO}
            alt={t("welcome_back")}
            className="logo"
          />
          <h1 className="fs-1 text-black fw-bold">KONGLA</h1>
        </div>
      </Row>
      <Row
        className=" home-card text-center p-2 p-md-5 m-1"
        style={{
          background:
            theme === "light"
            ? "var(--background-gradient-light)"
            : "var(--background-gradient-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <div className="pt-3 pb-3">
          <h2>{t("welcome")}</h2>
          <p className="m-md-5 fs-6 p-2 ps-md-5 pe-md-5 ">
            {t("please_login")}
          </p>

          <PrimaryButton onClick={handleClick} label={t("go_to_login")} />
        </div>
      </Row>
    </Container>
  );
};

export default LoggedOutHome;
