import React, { createContext, useState, useEffect, useContext } from "react";
import {  Label, Input } from "reactstrap";
import "./theme.css";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

const ThemeSwitcher = ({onClick}) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="theme-container">
      <Label check id="switch" className="switch">
        <Input
          type="checkbox"
          role="switch"
          checked={theme === "dark"}
          onChange={toggleTheme}
          onClick={onClick}
        />
        <span className="slider round"></span>
      </Label>
    </div>
  );
};

export { ThemeProvider, useTheme, ThemeSwitcher };
