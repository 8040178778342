import React, { useEffect, useState, useCallback } from "react";
import { Container, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "../theme/theme";
import useContent from "../hooks/useContent";
import { useParams, useNavigate } from "react-router-dom";
import { FaEye, FaTrash, FaCommentDots } from "react-icons/fa";
import "./Investigate.css";
import { formatDate } from "../utils/dateUtils";
import { DeleteModal } from "../components/deleteModal/DeleteModal";
import GenericList from "../components/genericList/GenericList";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "../components/buttons/Button";

const Investigate = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { contentId } = useParams();
  const { content, loading, getContent, deleteContentItem } =
    useContent(contentId);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortKey, setSortKey] = useState("title");
  const [filterKey, setFilterKey] = useState("all");
  const [expandedRow, setExpandedRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleSingleDeleteModal = () =>
    setSingleDeleteModal(!singleDeleteModal);

  const uniqueTypes = [...new Set(content.map((item) => item.type))];

  const applyFilterAndSort = useCallback(
    (items) => {
      let result = items;

      if (filterKey !== "all") {
        result = result.filter((item) => item.type === filterKey);
      }

      result.sort((a, b) => {
        if (sortKey === "latest") {
          return new Date(b.updated) - new Date(a.updated);
        } else if (sortKey === "oldest") {
          return new Date(a.updated) - new Date(b.updated);
        } else if (sortKey === "title") {
          return a.title.localeCompare(b.title);
        }
        return 0;
      });

      return result;
    },
    [filterKey, sortKey]
  );

  useEffect(() => {
    if (content) {
      const filtered = content.filter(
        (item) =>
          (item.title &&
            item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.description &&
            item.description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.text &&
            item.text.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredData(applyFilterAndSort(filtered));
    }
  }, [content, filterKey, sortKey, searchTerm, applyFilterAndSort]);

  const handleDeleteRowConfirmed = async () => {
    try {
      await deleteContentItem(rowToDelete);
      getContent();
      setFilteredData((prevData) =>
        prevData.filter((item) => item.id !== rowToDelete)
      );
      setSingleDeleteModal(false);
      setRowToDelete(null);
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  const columns = [
    {
      key: "title",
      label: t("title"),
    },
    {
      key: "description",
      label: t("description"),
    },
    {
      key: "type",
      label: t("type"),
    },
    {
      key: "created",
      label: t("created_date"),
      render: (item) => formatDate(item.created),
    },
    {
      key: "updated",
      label: t("updated_date"),
      render: (item) => formatDate(item.updated),
    },
  ];

  const renderActions = (item) => (
    <div className="d-flex gap-2">
      <PrimaryButton
        icon={FaEye}
        onClick={() => navigate(`/content/${item.id}`)}
        tooltipText={t("view_content")}
      />
      <CancelButton
        icon={FaTrash}
        onClick={() => {
          setRowToDelete(item.id);
          toggleSingleDeleteModal();
        }}
        tooltipText={t("delete")}
      />
      <SecondaryButton
        icon={FaCommentDots}
        onClick={() =>
          navigate("/dialog", {
            state: { contentIds: [item.id], contentTitles: [item.title] },
          })
        }
        tooltipText={t("create_thread")}
      />
    </div>
  );
  const handleBulkAction = (selectedIds) => {
    if (selectedIds.length === 0) return;
    navigate("/dialog", {
      state: {
        contentIds: selectedIds,
        contentTitles: selectedIds.map(
          (id) => filteredData.find((item) => item.id === id)?.title
        ),
      },
    });
  };
  return (
    <Container
      fluid
      className="investigate min-vh-100 p-5 container-xl"
      style={{ backgroundColor: theme === "light" ? "#ffffff" : "#262626" }}
    >
      {errorMessage && (
        <Alert color="danger" toggle={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      )}

      <GenericList
        loading={loading}
        data={filteredData}
        columns={columns}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filterKey={filterKey}
        setFilterKey={setFilterKey}
        uniqueFilterKeys={uniqueTypes}
        sortKey={sortKey}
        setSortKey={setSortKey}
        onRowClick={(item) =>
          setExpandedRow(expandedRow === item ? null : item)
        }
        renderActions={renderActions}
        expandedRow={expandedRow}
        toggleExpandedRow={(item) => (
          <>
            <p>
              {item.text || (
                <span className="unknown-value">
                  {t("No additional information available")}
                </span>
              )}
            </p>
          </>
        )}
        noResultsMessage={t("no_results_found")}
        onBulkAction={handleBulkAction}
      />

      {/* Delete Modal */}
      <DeleteModal
        isOpen={singleDeleteModal}
        toggle={toggleSingleDeleteModal}
        handleDelete={handleDeleteRowConfirmed}
      />
    </Container>
  );
};

export default Investigate;
