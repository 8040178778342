import React, { useEffect, useState, useRef } from "react";
import {
  Badge,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FaEdit, FaRocket } from "react-icons/fa";
import MessageListItem from "./MessageListItem";
import "./MessageList.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/theme";
import PrimaryButton, {
  CancelButton,
  OptionButton,
  SecondaryButton,
} from "../buttons/Button";
import { DeleteModal } from "../deleteModal/DeleteModal";
import { DropdownActions } from "../dropdownAction/DropdownAction";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import useContent from "../../hooks/useContent";
import useThreads from "../../hooks/useThreads";
import useMessages from "../../hooks/useMessages";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import ContentModal from "../content/ContentModal";

const MessageList = ({
  thread,
  onAddMessage,
  onExecuteContextChat,
  onSuccess,
  onEditThread,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [localMessages, setLocalMessages] = useState(thread.messages);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editTitle, setEditTitle] = useState(thread.title);
  const [editDescription, setEditDescription] = useState(thread.description);
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackColor, setFeedbackColor] = useState("success");
  const { content } = useContent();
  const { deleteThread, editThread } = useThreads();
  const { deleteMessageById } = useMessages();

  const navigate = useNavigate();

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [editSelectedContent, setEditSelectedContent] = useState(
    thread?.context?.map((item) => ({
      id: item.contentId,
      title: content.find((c) => c.id === item.contentId)?.title || "",
    })) || []
  );
  const [editSelectedContentModalOpen, setEditSelectedContentModalOpen] =
    useState(false);

  useEffect(() => {
    if (messagesEndRef.current) {
      const container = messagesEndRef.current.parentNode; // Get the parent scrollable container
      container.scrollTo({
        top: container.scrollHeight, // Scroll to the bottom of the container
        behavior: "smooth", // Smooth scrolling
      });
    }
  }, [localMessages]);

  useEffect(() => {
    setLocalMessages(thread.messages);
  }, [thread.messages]);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleEditSelectedContentModal = () => {
    setEditSelectedContentModalOpen(!editSelectedContentModalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      onAddMessage(thread.id, newMessage);
      setNewMessage("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Hindre linjeskift
      handleSubmit(e); // Send meldingen
    }
  };

  const onExecute = async () => {
    setLoading(true);
    try {
      await onExecuteContextChat(thread.id);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setShowCopyAlert(true);
        setTimeout(() => {
          setShowCopyAlert(false);
        }, 2000);
      })
      .catch(() => {
        alert(t("copy_failed"));
      });
  };

  const handleDelete = async () => {
    try {
      await deleteThread(thread.id);
      toggleDeleteModal();
      setFeedbackMessage(t("delete_success"));
      setFeedbackColor("success");
      setFeedbackModalOpen(true);
      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error deleting dialog:", error);
      setFeedbackMessage(t("delete_thread_error"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
      setTimeout(() => setFeedbackModalOpen(false), 3000);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setLocalMessages(
        localMessages.filter((message) => message.id !== messageId)
      );
    } catch (error) {
      console.error("Kunne ikke slette meldingen:", error);
    }
  };

  const handleEditClick = () => {
    setEditTitle(thread.title);
    setEditDescription(thread.description);

    const updatedSelectedContent =
      thread?.context?.map((item) => ({
        id: item.contentId,
        title:
          content.find((c) => c.id === item.contentId)?.title ||
          "Unknown Title",
      })) || [];

    setEditSelectedContent(updatedSelectedContent);
    toggleEditModal();
  };
  const handleEditContentSelect = (contentIds, contentList) => {
    const newSelectedItems = contentIds.map((id) => ({
      id,
      title: contentList.find((content) => content.id === id)?.title || "",
    }));

    // Merge existing and new selected items, ensuring no duplicates
    const mergedSelectedItems = [
      ...editSelectedContent,
      ...newSelectedItems.filter(
        (newItem) =>
          !editSelectedContent.some(
            (existingItem) => existingItem.id === newItem.id
          )
      ),
    ];

    setEditSelectedContent(mergedSelectedItems);
  };
  const handleRemoveSelectedContent = (id) => {
    const updatedContent = editSelectedContent.filter((item) => item.id !== id);
    setEditSelectedContent(updatedContent);
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();

    try {
      const updatedContext = editSelectedContent.map((item) => ({
        contentId: item.id,
        type: "manually_selected",
      }));

      const updatedThread = {
        title: editTitle || "Untitled",
        description: editDescription || "No description",
        context: updatedContext,
      };

      await editThread(thread.id, updatedThread);
      toggleEditModal();
      setFeedbackMessage(t("edit_feedback"));
      setFeedbackColor("success");
      setFeedbackModalOpen(true);

      setTimeout(() => onSuccess(), 3000);
    } catch (error) {
      console.error("Error editing dialog:", error);
      setFeedbackMessage(t("edit_feedback_error"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
      setTimeout(() => onSuccess(), 3000);
    }
  };

  const handleCancelEdit = () => {
    toggleEditModal();
  };

  const handleBadgeClick = (contextItemId) => {
    navigate(`/content/${contextItemId}`);
  };

  const adjustTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    setNewMessage(textarea.value);
  };

  return (
    <Container fluid className="container-xl message-container ">
      <Card
        style={{
          backgroundColor: theme === "light" ? "#ffffff" : "#000000",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="ms-4">
            <h5
              style={{
                color:
                  theme === "light"
                    ? "var(--primary-light)"
                    : "var(--primary-dark)",
              }}
            >
              {thread.title}
            </h5>
            <h6 className="mb-2">{thread.description}</h6>
          </div>
          <DropdownActions
            handlePrimaryAction={handleEditClick}
            handleCopyUrl={handleCopyUrl}
            toggleDeleteModal={toggleDeleteModal}
            showCopyAlert={showCopyAlert}
            primaryActionIcon={FaEdit}
            primaryActionLabel={t("edit")}
          />
        </div>
        <CardBody>
          {thread?.context?.length > 0 ? (
            <div className="ms-2">
              Selected Contents: {""}
              {thread.context.map((contextItem) => {
                const contentTitle =
                  content.find((c) => c.id === contextItem.contentId)?.title ||
                  "Unknown Title";
                return (
                  <Badge
                    key={contextItem.id}
                    className="me-2 custom-badge"
                    onClick={() => handleBadgeClick(contextItem.contentId)}
                    style={{ cursor: "pointer" }}
                  >
                    {contentTitle}
                  </Badge>
                );
              })}
            </div>
          ) : (
            <p className="ms-2">{t("no_selected_content")}</p>
          )}

          <div className="text-center">
            {loading ? (
              <LoadingOverlay size="small" isLoading={loading} />
            ) : (
              <PrimaryButton
                type="button"
                color="primary"
                onClick={onExecute}
                label={t("execute")}
                disabled={loading}
                icon={FaRocket}
              />
            )}
          </div>

          <div className="messages-item-container flex-grow-1">
            <ListGroup>
              {localMessages &&
                localMessages.map((message) => (
                  <MessageListItem
                    key={message.id}
                    message={message}
                    onDelete={() => handleDeleteMessage(message.id)}
                  />
                ))}
            </ListGroup>
            <div ref={messagesEndRef} />
          </div>
          <Form onSubmit={handleSubmit} className="mt-3 ">
            <FormGroup>
              <InputGroup>
                <Input
                  type="textarea"
                  name="message"
                  id="newMessage"
                  value={newMessage}
                  onChange={adjustTextareaHeight}
                  onKeyDown={handleKeyPress} // Lytt etter Enter-tasten
                  placeholder={t("enter_your_message")}
                  rows="3"
                  innerRef={textareaRef}
                  style={{
                    resize: "none",
                    overflowY: "auto",
                    maxHeight: "200px",
                    backgroundColor:
                      theme === "light"
                        ? "#ffffff"
                        : "var(--background-color-darker)",
                    color: theme === "light" ? "#000000" : "#ffffff",
                  }}
                />
                <InputGroupText
                  addonType="append"
                  style={{
                    backgroundColor:
                      theme === "light"
                        ? "#ffffff"
                        : "var(--background-color-darker)",
                    color: theme === "light" ? "#000000" : "#ffffff",
                  }}
                >
                  <PrimaryButton type="submit" label={t("add")} />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>

      <DeleteModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        handleDelete={handleDelete}
      />
      <FeedbackModal
        isOpen={feedbackModalOpen}
        message={feedbackMessage}
        color={feedbackColor}
      />
      <ContentModal
        isOpen={editSelectedContentModalOpen}
        toggle={toggleEditSelectedContentModal}
        onContentSelect={handleEditContentSelect}
      />

      <Modal
        isOpen={editModal}
        toggle={toggleEditModal}
        className="modal-center"
      >
        <ModalHeader toggle={toggleEditModal} className="text-primary">
          {t("edit_thread")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveEdit}>
            <FormGroup>
              <Label for="editTitle">{t("title")}</Label>
              <Input
                type="text"
                id="editTitle"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="editDescription">{t("description")}</Label>
              <Input
                type="text"
                id="editDescription"
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
              />
            </FormGroup>
            <OptionButton
              type="button"
              color="primary"
              onClick={toggleEditSelectedContentModal}
              label={t("select_content")}
              textColor="blue"
              borderColor="blue"
            />

            <div className="selected-content-badges mb-3">
              {editSelectedContent?.length > 0 ? (
                editSelectedContent.map((contextItem) => (
                  <div key={contextItem.id}>
                    <Badge color="primary">{contextItem.title}</Badge>
                    <button
                      type="button"
                      className="btn btn-light text-danger btn-sm"
                      onClick={() =>
                        handleRemoveSelectedContent(contextItem.id)
                      }
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p>{t("no_selected_content")}</p>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <CancelButton
                type="button"
                label={t("cancel")}
                onClick={handleCancelEdit}
              />
              <SecondaryButton type="submit" label={t("save")} />
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default MessageList;
