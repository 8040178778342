import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../auth/authContext";
import "./Login.css";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../components/buttons/Button";
import { useTheme } from "../theme/theme";
import { Container, Row } from "reactstrap";

const DEFAULT_PINECONE_PHOTO = "/pinecone-big.png";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const from = location.state?.from?.pathname || "/";
  const { theme } = useTheme();

  const onLoginClick = async () => {
    try {
      await login();
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Container fluid className="login-page text-center p-2 p-md-5">
      <Row className="text-center align-self-center m-1 m-md-2 pb-md-3">
        <div>
          <img
            src={DEFAULT_PINECONE_PHOTO}
            alt={t("welcome_back")}
            className="logo"
          />
          <h1 className="fs-1 text-black fw-bold">KONGLA</h1>
          </div>
      </Row>
      <Row
        className="login-card text-center p-2 p-md-5 m-1"
        style={{
          background:
            theme === "light"
            ? "var(--background-gradient-light)"
            : "var(--background-gradient-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
        }}
      >
        <div className="pt-3 pb-3">
        <h2>{t("welcome")}</h2>
        <p className="m-md-5 fs-6 p-2 ps-md-5 pe-md-5 ">
            {t("please_login_to_access")}
          </p>

          <PrimaryButton
            onClick={onLoginClick}
            label={t("login_with_microsoft")}
          />
        </div>
      </Row>
    </Container>
  );
};

export default Login;
