import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "reactstrap";
import "./ContentModal.css";
import { useTranslation } from "react-i18next";
import useContent from "../../hooks/useContent";
import { useTheme } from "../../theme/theme";
import { CancelButton, SecondaryButton } from "../buttons/Button";
import { formatDate } from '../../utils/dateUtils';
import SearchBar from "../searchbar/SearchBar"; // Import the new SearchBar component

const ContentModal = ({ isOpen, toggle, onContentSelect }) => {
    const { content, loading } = useContent();
    const [selectedContent, setSelectedContent] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();
    const { theme } = useTheme();

    const handleContentToggle = (contentId) => {
        setSelectedContent((prevSelectedContent) =>
            prevSelectedContent.includes(contentId)
                ? prevSelectedContent.filter((id) => id !== contentId)
                : [...prevSelectedContent, contentId]
        );
    };

    const handleAddContent = () => {
        onContentSelect(
            selectedContent,
            content.filter((content) => selectedContent.includes(content.id))
        );
        toggle();
    };

    const filteredContent = content.filter((item) =>
        item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <Modal isOpen={isOpen} toggle={toggle} className="content-modal">
            <ModalHeader
                toggle={toggle}
                style={{
                    backgroundColor: "var(--primary-dark)",
                }}
            >
                {t("select_content")}
            </ModalHeader>
            <ModalBody
                style={{
                    backgroundColor:
                        theme === "light"
                            ? "var(--background-color)"
                            : "var(--background-color-dark)",
                            color: theme === "light" ? "var(--dark)" : "var(--light)",
                        }}
            >
                {/* Use the new SearchBar component */}
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

                {loading ? (
                    <Spinner color="primary" />
                ) : (
                    <ListGroup>
                        {filteredContent.map((content) => (
                            <ListGroupItem
                                key={content.id}
                                onClick={() => handleContentToggle(content.id)}
                                active={selectedContent.includes(content.id)}
                                className="content-list-item"
                                style={{
                                    backgroundColor:
                                        theme === "light"
                                            ? "var(--background-color)"
                                            : "var(--background-color-darker)",
                                            color: theme === "light" ? "var(--dark)" : "var(--light)",

                                }}
                                action
                            >
                                <div className="content-title">
                                    {content.title ? content.title : t("no_title")}
                                </div>
                                <div className="content-description">
                                    {content.description ? content.description : t("no_description")}
                                </div>
                                <div className="content-date">
                                    {formatDate(content.created)}
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
            </ModalBody>
            <ModalFooter
                style={{
                    backgroundColor:
                        theme === "light"
                            ? "var(--background-color)"
                            : "var(--background-color-dark)",
                }}
            >
                <SecondaryButton
                    color="primary"
                    onClick={handleAddContent}
                    label={t("add_selected_content")}
                />
                <CancelButton color="secondary" onClick={toggle} label={t("cancel")} />
            </ModalFooter>
        </Modal>
    );
};

export default ContentModal;
