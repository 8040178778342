import axios from 'axios';

const API_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/actions/classification/better-classify`;

const fetchData = async (url, accessToken, data) => {
    try {
        const contextId = localStorage.getItem('selectedContext');
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Context-Id': contextId || '',
        };
        const config = {
            method: 'post',
            url,
            headers,
            data,
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

export const betterClassifyCategory = async (categoryData, accessToken) => {
    return await fetchData(API_URL, accessToken, categoryData);
};
