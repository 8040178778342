import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Row, Col, Input } from "reactstrap";
import SearchBar from "../searchbar/SearchBar";
import { useTranslation } from "react-i18next";
import "./GenericList.css";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import { useTheme } from "../../theme/theme";
import PrimaryButton from "../buttons/Button";

const GenericList = ({
  loading,
  data,
  columns,
  searchTerm,
  setSearchTerm,
  filterKey,
  setFilterKey,
  uniqueFilterKeys,
  sortKey,
  setSortKey,
  onRowClick,
  renderActions,
  expandedRow,
  toggleExpandedRow,
  rowEqualityCheck = (row1, row2) => row1 === row2, // Standard object reference check
  noResultsMessage,
  renderPrimaryActions,
  tableDescription, // New prop for table description
  onBulkAction, // New prop for bulk action
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const isRowSelected = (id) => selectedRows.includes(id);

  return (
    <div className="generic-table-container">
      {tableDescription && (
        <div className="table-description mb-2">
          <p>{tableDescription}</p>
        </div>
      )}
      <Row className="mb-3 align-items-center">
        <Col md={filterKey || sortKey ? 6 : 8} className="mx-auto">
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder={t("search_placeholder")}
          />
        </Col>
        {setFilterKey && uniqueFilterKeys && (
          <Col md="3">
            <Input
              type="select"
              value={filterKey}
              onChange={(e) => setFilterKey(e.target.value)}
              aria-label={t("filter")}
              style={{
                backgroundColor:
                  theme === "light"
                    ? "#ffffff"
                    : "var(--background-color-darker)",
                color: theme === "light" ? "#000000" : "#ffffff",
              }}
              className="my-1"
            >
              <option value="all">{t("all")}</option>
              {uniqueFilterKeys.map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Input>
          </Col>
        )}
        {setSortKey && (
          <Col md="3">
            <Input
              type="select"
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
              aria-label={t("sort_by")}
              style={{
                backgroundColor:
                  theme === "light"
                    ? "#ffffff"
                    : "var(--background-color-darker)",
                color: theme === "light" ? "#000000" : "#ffffff",
              }}
              className="my-1"
            >
              <option value="title">{t("sort_by") + " " + t("title")}</option>
              <option value="latest">{t("sort_by") + " " + t("latest")}</option>
              <option value="oldest">{t("sort_by") + " " + t("oldest")}</option>
            </Input>
          </Col>
        )}

        {renderPrimaryActions && (
          <Col md="3">
            <div onClick={(e) => e.stopPropagation()}>
              {renderPrimaryActions()}
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        {onBulkAction && (
          <PrimaryButton
            className="mb-3"
            onClick={() => onBulkAction(selectedRows)}
            disabled={selectedRows.length === 0}
            label={t("create_thread")}
          />
        )}
        {loading && <LoadingOverlay isLoading={loading} />}
        {!loading && data.length === 0 ? (
          <div className="text-center mt-4">
            {noResultsMessage || t("no_results_found")}
          </div>
        ) : (
          <Table
            striped
            bordered
            responsive
            hover
            style={{
              backgroundColor:
                theme === "light"
                  ? "#ffffff"
                  : "var(--background-color-darker)",
              color: theme === "light" ? "#000000" : "#ffffff",
              borderColor: theme === "light" ? "#dee2e6" : "#343a40",
            }}
            className={`generic-table ${theme === "dark" ? "table-dark" : ""}`}
          >
            <thead>
              <tr>
                {onBulkAction && (
                  <th>
                    <Input
                      type="checkbox"
                      onChange={(e) =>
                        setSelectedRows(
                          e.target.checked ? data.map((item) => item.id) : []
                        )
                      }
                      checked={selectedRows.length === data.length}
                    />
                  </th>
                )}
                {columns.map((column) => (
                  <th
                    key={column.key}
                    style={{
                      backgroundColor:
                        theme === "light" ? "#f8f9fa" : "#495057",
                      color: theme === "light" ? "#000000" : "#ffffff",
                    }}
                  >
                    {column.label}
                  </th>
                ))}
                {renderActions && (
                  <th
                    style={{
                      backgroundColor:
                        theme === "light" ? "#f8f9fa" : "#495057",
                      color: theme === "light" ? "#000000" : "#ffffff",
                    }}
                  >
                    {t("actions")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={columns.length + (renderActions ? 1 : 0)}>
                    <div style={{ minHeight: "200px" }} />
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          theme === "light" ? "#ffffff" : "#343a40",
                        color: theme === "light" ? "#000000" : "#ffffff",
                      }}
                    >
                      {onBulkAction && (
                        <td>
                          <Input
                            type="checkbox"
                            checked={isRowSelected(item.id)}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </td>
                      )}
                      {columns.map((column) => (
                        <td
                          key={column.key}
                          onClick={() => onRowClick && onRowClick(item)}
                        >
                          {column.render
                            ? column.render(item)
                            : item[column.key]}
                        </td>
                      ))}
                      {renderActions && (
                        <td>
                          <div
                            className="action-buttons"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {renderActions(item)}
                          </div>
                        </td>
                      )}
                    </tr>
                    {rowEqualityCheck(expandedRow, item) && (
                      <tr>
                        <td
                          colSpan={
                            columns.length +
                            (renderActions ? 1 : 0) +
                            (onBulkAction ? 1 : 0) // Add 1 for the checkbox column if onBulkAction is enabled
                          }
                        >
                          {toggleExpandedRow ? (
                            <div
                              className="metadata-section metadata-section-expand"
                              style={{
                                backgroundColor:
                                  theme === "light" ? "#f8f9fa" : "#495057",
                                color:
                                  theme === "light" ? "#000000" : "#ffffff",
                              }}
                            >
                              {toggleExpandedRow(item)}
                            </div>
                          ) : (
                            <div className="metadata-section">
                              <p>{t("No expanded content provided.")}</p>
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

GenericList.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  filterKey: PropTypes.string,
  setFilterKey: PropTypes.func,
  uniqueFilterKeys: PropTypes.arrayOf(PropTypes.string),
  sortKey: PropTypes.string,
  setSortKey: PropTypes.func,
  onRowClick: PropTypes.func,
  renderActions: PropTypes.func,
  expandedRow: PropTypes.any,
  toggleExpandedRow: PropTypes.func,
  rowEqualityCheck: PropTypes.func,
  noResultsMessage: PropTypes.string,
  tableDescription: PropTypes.string, // New prop type
  renderPrimaryActions: PropTypes.func,
  onBulkAction: PropTypes.func, // New prop type
};

export default GenericList;
