import React, { useEffect, useState } from "react";
import ModelListItem from "./ModelListItem";
import { Container, ListGroup } from "reactstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/theme";
import LoadingOverlay from "../loadingOverlay/LoadingOverlay";
import useModels from "../../hooks/useModels";
import "./ModelList.css";

const ModelList = ({ initialModelId }) => {
  const { models, loading } = useModels(initialModelId);
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [showModels, setShowModels] = useState(false);
  const [selectedModel, setSelectedModel] = useState(() => {
    // Retrieve the selected model from localStorage or set default
    const storedModel = localStorage.getItem("selectedModelName");
    return storedModel ? JSON.parse(storedModel) : { name: "GPT4Azure" };
  });

  const toggleModels = () => setShowModels(!showModels);

  const handleModelSelection = (modelId) => {
    const model = models.find((m) => m.id === modelId);
    if (model) {
      setSelectedModel(model);
      console.log(model);
      localStorage.setItem("selectedModelName", JSON.stringify(model));
    }
  };

  useEffect(() => {
    // Set default model name in localStorage if not already set
    if (!localStorage.getItem("selectedModelName")) {
      localStorage.setItem(
        "selectedModelName",
        JSON.stringify({ name: "GPT4Azure" })
      );
    }
  }, []);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <LoadingOverlay size="small" isLoading={loading} />
      </Container>
    );
  }

  return (
    <div className="text-start">
      <div
        className="btn btn-outline-primary mb-4 mt-4"
        onClick={toggleModels}
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--background-color)"
              : "var(--background-color-dark)",
          color: theme === "light" ? "#000000" : "#ffffff",
          borderColor:
            theme === "light" ? "var(--primary-light)" : "var(--primary-dark)",
        }}
      >
        {showModels ? (
          <>
            {t("hide_models")} <FaChevronUp />
          </>
        ) : (
          <>
            {t("show_models")} <FaChevronDown />
          </>
        )}
      </div>

      {showModels && (
        <ListGroup
          className="model-list w-100"
          style={{
            backgroundColor:
              theme === "light"
                ? "var(--background-color)"
                : "var(--background-color-dark)",
            color: theme === "light" ? "#000000" : "var(--primary-dark)",
            borderColor:
              theme === "light"
                ? "var(--primary-light)"
                : "var(--primary-dark)",
          }}
        >
          {models.map((model) => (
            <ModelListItem
              key={model.id}
              model={model}
              onToggle={() => handleModelSelection(model.id)}
              isSelected={selectedModel?.id === model.id}
            />
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default ModelList;
