import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_FAVN_API_BASE_URI}/v1/actions/dialogue`;

const fetchData = async (url, accessToken, method = "get") => {
  try {
    const contextId = localStorage.getItem("selectedContext");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Context-Id": contextId || "",
      "Content-Type": "application/json",
    };

    const config = {
      method,
      url,
      headers,
    };

    console.log("Request Config:", config); // Debug request configuration

    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("API Response Error:", error.response.data);
      console.error("Status Code:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Request Error:", error.message);
    }
    throw error;
  }
};

export const fetchModels = async (accessToken) => {
  const url = `${API_BASE_URL}/getModelNames`;
  const data = await fetchData(url, accessToken);
  return data.map((name, index) => ({ id: index + 1, name }));
};
