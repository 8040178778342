import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";
import { FaPaperPlane, FaTimes, FaUpload } from "react-icons/fa";
import { useTheme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { CancelButton, SecondaryButton, OptionButton } from "../buttons/Button";
import "./ContentForm.css";
import useContent from "../../hooks/useContent";
import FeedbackModal from "../feedbackModal/FeedbackModal";
import { parseFile } from "../../utils/fileUtils";

const ContentForm = ({ content, onSuccess, onCancel }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { createContentItem, updateContentItem } = useContent();

  const [contentData, setContentData] = useState({
    description: "",
    text: "",
    title: "",
    type: "",
    url: "",
  });
  const [tableData, setTableData] = useState([]); // For table content
  const [isTable, setIsTable] = useState(false); // Flag to determine if content is a table
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileName, setFileName] = useState(""); // For displaying the uploaded file name
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackColor, setFeedbackColor] = useState("success");

  useEffect(() => {
    setContentData(
      content || {
        description: "",
        text: "",
        title: "",
        type: "",
        url: "",
      }
    );

    if (content && content.text) {
      try {
        const parsed = JSON.parse(content.text);
        if (Array.isArray(parsed) && parsed.every(Array.isArray)) {
          setIsTable(true);
          setTableData(parsed);
        } else {
          setIsTable(false);
        }
      } catch (error) {
        setIsTable(false);
      }
    }
  }, [content]);

  useEffect(() => {
    if (feedbackModalOpen) {
      const timer = setTimeout(() => setFeedbackModalOpen(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [feedbackModalOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name next to the button
      try {
        const { content } = await parseFile(file);

        if (Array.isArray(content)) {
          // Handle table-like content for Excel or CSV files
          setIsTable(true);
          setTableData(content);
          setContentData((prevData) => ({
            ...prevData,
            title: file.name.replace(/\.[^/.]+$/, ""), // Set title to file name without extension
          }));
        } else {
          // Handle line-based content for .doc files
          setIsTable(false);
          setContentData((prevData) => ({
            ...prevData,
            text: content, // Store parsed content
            title: file.name.replace(/\.[^/.]+$/, ""), // Set title to file name without extension
          }));
        }
      } catch (error) {
        console.error("Error parsing file:", error);
        setFeedbackMessage(t("file_parse_error"));
        setFeedbackColor("danger");
        setFeedbackModalOpen(true);
      }
    } else {
      setFeedbackMessage(t("invalid_file_format"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handleTableChange = (rowIndex, colIndex, value) => {
    const updatedTable = tableData.map((row, rIdx) =>
      rIdx === rowIndex
        ? row.map((cell, cIdx) => (cIdx === colIndex ? value : cell))
        : row
    );
    setTableData(updatedTable);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const finalContent = isTable
        ? JSON.stringify(tableData) // Excel/CSV content as JSON
        : contentData.text; // Editable text content

      let newContent;
      if (content && content.id) {
        newContent = await updateContentItem(content.id, {
          ...contentData,
          text: finalContent,
        });
      } else {
        newContent = await createContentItem({
          ...contentData,
          text: finalContent,
        });
      }

      setFeedbackMessage(t("content_success"));
      setFeedbackColor("success");
      setFeedbackModalOpen(true);
      setTimeout(() => onSuccess(newContent), 3000);
    } catch (error) {
      console.error("Error submitting content:", error);
      setFeedbackMessage(t("content_error"));
      setFeedbackColor("danger");
      setFeedbackModalOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="content-form">
      <Card
        style={{
          backgroundColor:
            theme === "light"
              ? "var(--card-background-color)"
              : "var(--card-background-color-dark)",
          color: theme === "light" ? "var(--dark)" : "var(--light)",
        }}
      >
        <CardBody>
          <CardTitle
            tag="h1"
            className="text-center"
            style={{
              color:
                theme === "light" ? "var(--primary)" : "var(--primary-dark)",
            }}
          >
            {content && content.id ? t("edit_content") : t("add_new_content")}
          </CardTitle>

          <FormGroup className="text-primary d-flex align-items-center">
            <input
              type="file"
              id="fileInput"
              name="file"
              onChange={handleFileUpload}
              accept=".doc,.docx,.xls,.xlsx,.csv"
              style={{ display: "none" }} // Hide the actual file input
            />
            <OptionButton
              type="button"
              textColor="blue"
              iconColor="blue"
              borderColor="blue"
              onClick={triggerFileInput}
              icon={FaUpload}
              label={t("upload_file")}
              disabled={isSubmitting}
            />
            {fileName && <span className="ms-3">{fileName}</span>}{" "}
            {/* Display file name */}
          </FormGroup>

          <Form onSubmit={handleSubmit}>
            <FormField
              type="text"
              name="title"
              id="title"
              value={contentData.title}
              onChange={(e) => handleChange(e)}
              disabled={isSubmitting}
              label={t("title")}
            />
            <FormField
              type="text"
              name="description"
              id="description"
              value={contentData.description}
              onChange={(e) => handleChange(e)}
              disabled={isSubmitting}
              label={t("description")}
            />
            <FormField
              type={isTable ? "table" : "textarea"}
              name="text"
              id="text"
              value={isTable ? tableData : contentData.text}
              onChange={handleChange}
              onTableChange={handleTableChange}
              disabled={isSubmitting}
              label={t("text")}
            />

            <Row>
              <Col xl="3">
                <FormField
                  type="text"
                  name="type"
                  id="type"
                  value={contentData.type}
                  onChange={(e) => handleChange(e)}
                  disabled={isSubmitting}
                  label={t("type")}
                />
              </Col>
              <Col xl="9">
                <FormField
                  type="url"
                  name="url"
                  id="url"
                  value={contentData.url}
                  onChange={(e) => handleChange(e)}
                  disabled={isSubmitting}
                  label={t("url")}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-center mt-3">
              <CancelButton
                label={t("cancel")}
                icon={FaTimes}
                type="button"
                onClick={onCancel}
              />
              <SecondaryButton
                type="button"
                disabled={isSubmitting}
                icon={FaPaperPlane}
                label={isSubmitting ? t("submitting") : t("submit")}
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </CardBody>
      </Card>

      <FeedbackModal
        isOpen={feedbackModalOpen}
        message={feedbackMessage}
        color={feedbackColor}
        toggle={() => setFeedbackModalOpen(false)}
      />
    </Container>
  );
};

const FormField = ({
  label,
  type,
  name,
  id,
  value,
  onChange,
  onTableChange,
  disabled,
}) => {
  const { theme } = useTheme();

  return (
    <FormGroup className="input-field">
      <Label
        for={id}
        style={{
          color: theme === "light" ? "var(--primary)" : "var(--primary-dark)",
        }}
      >
        {label}{" "}
      </Label>
      {type === "table" ? (
        <div
          style={{
            overflowX: "auto",
            backgroundColor:
              theme === "light"
                ? "var(--light)"
                : "var(--background-color-darker)",
          }}
        >
          <Table striped bordered responsive>
            <tbody>
              {value.map((row, rowIdx) => (
                <tr key={`row-${rowIdx}`}>
                  {row.map((cell, colIdx) => (
                    <td key={`cell-${rowIdx}-${colIdx}`}>
                      <Input
                        type="text"
                        value={cell}
                        onChange={(e) =>
                          onTableChange(rowIdx, colIdx, e.target.value)
                        }
                        disabled={disabled}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Input
          type={type}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{
            backgroundColor:
              theme === "light"
                ? "var(--light)"
                : "var(--background-color-darker)",
            color: theme === "light" ? "var(--dark)" : "var(--light)",
          }}
          className={type === "textarea" ? "input-textarea" : ""} // Added class conditionally
        />
      )}
    </FormGroup>
  );
};

export default ContentForm;
