import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { FaTrashAlt, FaDownload } from "react-icons/fa";
import { formatDate } from "../utils/dateUtils";
import useClassificationFiles from "../hooks/useClassificationFiles";
import PrimaryButton, {
  CancelButton,
  SecondaryButton,
} from "../components/buttons/Button";
import GenericList from "../components/genericList/GenericList";
import { useTranslation } from "react-i18next";
import "./Classify.css";
import { useTheme } from "../theme/theme";
import { FaUpload } from "react-icons/fa";
import { DeleteModal } from "../components/deleteModal/DeleteModal";

const Classify = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  // Funksjoner fra useClassificationFiles
  const {
    fetchClassificationFiles,
    uploadClassificationFile,
    deleteClassificationFile,
    downloadClassificationFile,
  } = useClassificationFiles();

  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const fileInputRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  // Laster filer
  const loadFiles = async () => {
    setLoading(true);
    try {
      const fetchedFiles = await fetchClassificationFiles();
      setFiles(fetchedFiles);
      setFilteredFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ingen avhengigheter her

  // Filtrering basert på søk
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
    const filtered = files.filter((file) =>
      file.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredFiles(filtered);
  }, [searchTerm, files]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validMimeTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (!validMimeTypes.includes(file.type)) {
        alert(t("invalidFileType"));
        return;
      }

      setLoading(true);
      try {
        await uploadClassificationFile(file);
        await loadFiles();
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current?.click();
  };

  const confirmDelete = (fileName) => {
    setFileToDelete(fileName);
    toggleDeleteModal();
  };

  const handleDeleteConfirmed = async () => {
    setLoading(true);
    try {
      await deleteClassificationFile(fileToDelete);
      await loadFiles();
      toggleDeleteModal();
      setFileToDelete(null);
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (fileName) => {
    try {
      await downloadClassificationFile(fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const toggleRow = (file) => {
    setExpandedRow((prevRow) => (prevRow === file ? null : file));
  };

  const columns = [
    { key: "name", label: t("File Name") },
    {
      key: "uploadedat",
      label: t("Uploaded Time"),
      render: (file) =>
        file.uploadedat ? formatDate(file.uploadedat) : t("Unknown"),
    },
  ];

  return (
    <Container
      fluid
      className=" min-vh-100 p-5 container-xl"
      style={{ backgroundColor: theme === "light" ? "#ffffff" : "#262626" }}
    >
      <section className="position-sticky fixed-top">
        <Row>
          <Col>
            <GenericList
              loading={loading}
              data={filteredFiles}
              columns={columns}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onRowClick={toggleRow}
              expandedRow={expandedRow}
              toggleExpandedRow={(file) => (
                <>
                  <p>
                    <strong>{t("Size")}:</strong>{" "}
                    {file.size ? (
                      `${(file.size / 1024).toFixed(2)} KB`
                    ) : (
                      <span className="unknown-value">{t("Unknown")}</span>
                    )}
                  </p>
                  <p>
                    <strong>{t("Uploaded By")}:</strong>{" "}
                    {file.uploadedby || (
                      <span className="unknown-value">{t("Unknown")}</span>
                    )}
                  </p>
                  <p>
                    <strong>{t("Uploaded At")}:</strong>{" "}
                    {file.uploadedat && file.uploadedat !== "unknown" ? (
                      formatDate(file.uploadedat)
                    ) : (
                      <span className="unknown-value">{t("Unknown")}</span>
                    )}
                  </p>
                  <p>
                    <strong>{t("File Type")}:</strong>{" "}
                    {file.filetype || (
                      <span className="unknown-value">{t("Unknown")}</span>
                    )}
                  </p>
                  <p>
                    <strong>{t("Checksum")}:</strong>{" "}
                    {file.checksum || (
                      <span className="unknown-value">{t("Unknown")}</span>
                    )}
                  </p>
                </>
              )}
              renderActions={(item) => {
                return (
                  <div className="d-flex gap-2">
                    <SecondaryButton
                      icon={FaDownload}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(item.name);
                      }}
                      tooltipText={t("download")}
                    />
                    <CancelButton
                      icon={FaTrashAlt}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(item.name);
                      }}
                      tooltipText={t("delete")}
                    />
                  </div>
                );
              }}
              renderPrimaryActions={() => (
                  <div className="d-flex gap-2">
                    <PrimaryButton
                        icon={FaUpload}
                        onClick={triggerFileUpload}
                        label={t("upload_file")}
                    />
                  </div>
              )}
              noResultsMessage={t("No files found")}
            />
          </Col>
        </Row>
      </section>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <DeleteModal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        handleDelete={handleDeleteConfirmed}
      />
    </Container>
  );
};

export default Classify;
