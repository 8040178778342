import React from "react";
import { ListGroupItem } from "reactstrap";
import "./ContextListItem.css";
import { useTheme } from "../../theme/theme";

const ContextListItem = ({ context, onSelectContext, isSelected }) => {
    const { theme } = useTheme();

    return (
        <ListGroupItem
            onClick={() => onSelectContext(context)}
            style={{
                backgroundColor:
                    theme === "light"
                        ? "#ffffff"
                        : "var(--background-color-darker)",
                color: theme === "light" ? "#000000" : "#ffffff",
            }}
            className={`context-list-item ${isSelected ? "selected" : ""}`}
        >
            <div>
                <h5>{context.name}</h5>
                <p>{context.description}</p>
            </div>
        </ListGroupItem>
    );
};

export default ContextListItem;
