import React from "react";
import "./ButtonGroup.css";

const ButtonGroup = ({ buttons }) => {
    return (
        <div className="button-group">
            {buttons.map((button, index) => (
                <div key={index} className="button-group-item">
                    {button}
                </div>
            ))}
        </div>
    );
};

export default ButtonGroup;