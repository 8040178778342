import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";

const LanguageSelector = ({onClick}) => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setSelectedLanguage(lng);
    if (onClick) onClick();

  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret   color="dark">
        {selectedLanguage === "en" ? (
          <>
            <Flag
              code="GB"
              alt="English Flag"
              width="20"
              height="15"

            />
          </>
        ) : (
          <>
            <Flag
              code="NO"
              alt="Norwegian Flag"
              width="20"
              height="15"

            />
          </>
        )}{" "}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changeLanguage("en")}>
        <Flag
            code="GB"
            alt="English Flag"
            width="20"

            className="me-2"
          />English
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("no")}>
          {" "}
          <Flag
            code="NO"
            alt="Norwegian Flag"
            width="20"

            className="me-2"
          />
          Norsk
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;
