import React, { useState, useEffect } from "react";
import {
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import { FaTimes, FaPaperPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { CancelButton, SecondaryButton } from "../buttons/Button";
import useContextRegister from "../../hooks/useContextRegister";
import "./ContextForm.css";
import { useTheme } from "../../theme/theme";
import FeedbackModal from "../feedbackModal/FeedbackModal";

const ContextForm = ({ context, onSuccess, onCancel }) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const { createContextItem, updateContextItem } = useContextRegister();
    const [contextData, setContextData] = useState({
        name: "",
        description: "",
        type: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [feedbackColor, setFeedbackColor] = useState("success");
    useEffect(() => {
        setContextData(
            context || {
                name: "",
                description: "",
                type: "",
            }
        );
    }, [context]);
    useEffect(() => {
        if (feedbackModalOpen) {
          const timer = setTimeout(() => setFeedbackModalOpen(false), 3000);
          return () => clearTimeout(timer);
        }
      }, [feedbackModalOpen]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContextData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            let newContext;
            if (context && context.id) {
                newContext = await updateContextItem(context.id, contextData);
            } else {
                newContext = await createContextItem(contextData);
            }
           
            setFeedbackMessage(t("content_success"));
            setFeedbackColor("success");
            setFeedbackModalOpen(true);
            setTimeout(() =>  onSuccess(newContext), 3000);
        } catch (error) {
            console.error("Error submitting context:", error);
            setFeedbackMessage(t("content_error"));
            setFeedbackColor("danger");
            setFeedbackModalOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container className="context-form">
            <Card   style={{
          backgroundColor:
            theme === "light"
              ? "var(--card-background-color)"
              : "var(--card-background-color-dark)",
          color: theme === "light" ? "var(--dark)" : "var(--light)",
        }}>
                <CardBody>
                    <CardTitle tag="h1" className="text-center"     style={{
              color:
                theme === "light" ? "var(--primary)" : "var(--primary-dark)",
            }}>
                        {context && context.id ? t("edit_context") : t("add_new_context")}
                    </CardTitle>
                    <Form onSubmit={handleSubmit}>
                        <FormField
                            type="text"
                            name="name"
                            id="name"
                            value={contextData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("name")}
                        />
                        <FormField
                            type="textarea"
                            name="description"
                            id="description"
                            value={contextData.description}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("description")}
                        />
                        <FormField
                            type="text"
                            name="type"
                            id="type"
                            value={contextData.type}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            label={t("type")}
                        />
                        <div className="d-flex justify-content-center">
                            <CancelButton
                                label={t("cancel")}
                                icon={FaTimes}
                                type="button"
                                onClick={onCancel}
                            />
                            <SecondaryButton
                                type="submit"
                                icon={FaPaperPlane}
                                label={isSubmitting ? t("submitting") : t("submit")}
                                disabled={isSubmitting}
                            />
                        </div>
                    </Form>
                </CardBody>
            </Card>
            <FeedbackModal
        isOpen={feedbackModalOpen}
        message={feedbackMessage}
        color={feedbackColor}
        toggle={() => setFeedbackModalOpen(false)}
      />
        </Container>
    );
};

const FormField = ({ label, type, name, id, value, onChange, disabled }) => {
    const { theme } = useTheme();
    const [textareaHeight, setTextareaHeight] = useState("auto");

  useEffect(() => {
    if (type === "textarea") {
      setTextareaHeight("auto");
      const textarea = document.getElementById(id);
      if (textarea) {
        setTextareaHeight(`${textarea.scrollHeight}px`);
      }
    }
  }, [value, id, type]);

  const handleInputChange = (e) => {
    onChange(e);
    if (type === "textarea") {
      const textarea = e.target;
      setTextareaHeight(`${textarea.scrollHeight}px`);
    }
  };
return (
    <FormGroup className="p-3">
    <Label for={id} tag="h5">
        {label}
    </Label>
    <Input
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={handleInputChange}
        disabled={disabled}
        style={{
            backgroundColor: theme === "light" ? "var(--light)" : "var(--background-color-darker)",
            color: theme === "light" ? "var(--dark)" : "var(--light)",
            height: type === "textarea" ? textareaHeight : "auto",
            overflowY: "auto", // Vertikal scroll
            overflowX: "hidden", // Forhindrer horisontal scroll
          }}
    />
</FormGroup>
)
   
};

export default ContextForm;
