import * as XLSX from "xlsx";
import * as mammoth from "mammoth";

/**
 * Parses a .doc or .docx file and extracts its text content.
 * @param {File} file - The Word file to parse.
 * @returns {Promise<{content: string, fileName: string}>} Parsed content and file name.
 */
export const parseDocFile = async (file) => {
  const fileContent = await file.arrayBuffer();
  const result = await mammoth.extractRawText({ arrayBuffer: fileContent });
  return {
    content: result.value,
    fileName: file.name,
  };
};

/**
 * Parses a .xls or .xlsx file and converts it to HTML table format.
 * @param {File} file - The Excel file to parse.
 * @returns {Promise<{content: string, fileName: string}>} Parsed HTML table and file name.
 */
export const parseExcelFile = async (file) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
  
    // Convert sheet to JSON (array of arrays)
    const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
    return { content: jsonSheet, fileName: file.name };
  };
  /**
 * Parses a .csv file and extracts its content.
 * @param {File} file - The CSV file to parse.
 * @returns {Promise<{content: string, fileName: string}>} Parsed content and file name.
 */
export const parseCSVFile = async (file) => {
    const data = await file.text(); // Read the file as text
    const workbook = XLSX.read(data, { type: "string" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    
    // Convert sheet to JSON (array of arrays)
    const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
    return { content: jsonSheet, fileName: file.name };
  };
  /**
 * Determines the file type and parses the file accordingly.
 * @param {File} file - The file to parse.
 * @returns {Promise<{content: string, fileName: string}>} Parsed content and file name.
 */
export const parseFile = async (file) => {
    const fileType = file.type;
    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return parseDocFile(file);
    }
  
    if (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return parseExcelFile(file);
    }
  
    if (fileType === "text/csv" || fileExtension === "csv") {
      return parseCSVFile(file);
    }
  
    throw new Error("Unsupported file format");
  };
  
