import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./InformationModal.css";

const InformationModal = ({ isOpen, toggle, title, content, modalClassName }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} modalClassName={modalClassName}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>{content}</ModalBody>
        </Modal>
    );
};

InformationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    modalClassName: PropTypes.string, // Ny prop for å støtte unik styling
};

InformationModal.defaultProps = {
    modalClassName: "information-modal", // Standardklasse for modalen
};

export default InformationModal;
