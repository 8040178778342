import React from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./SearchBar.css"
import { useTheme } from "../../theme/theme";

const SearchBar = ({ searchTerm, setSearchTerm, placeholder }) => {
    const { t } = useTranslation();
    const { theme } = useTheme();

    return (
        <Input
            type="text"
            placeholder={placeholder || t("search_content")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
            style={{
                backgroundColor:
                    theme === "light"
                        ? "#ffffff"
                        : "var(--background-color-darker)",
                color: theme === "light" ? "#000000" : "#ffffff",
            }}
        />
    );
};

export default SearchBar;
