import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Collapse,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faSignInAlt,
  faBook,
  faComments,
  faSearch,
  faBars,
  faTimes,
  faCogs,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth, useAuthStatus } from "../../auth/authContext";
import LogoutModal from "../LogoutModal";
import "./NavBar.css";
import { ThemeSwitcher } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../language/LanguageSelector";
import NavBarItem from "./NavItem";
import useContextRegister from "../../hooks/useContextRegister";

const NavBar = () => {
  const isAuthenticated = useAuthStatus();
  const { instance, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const toggle = () => setIsOpen(!isOpen);
  const toggleModal = () => setModal(!modal);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // State for contexts
  const { contexts, getContexts } = useContextRegister();
  const [selectedContext, setSelectedContext] = useState(
    localStorage.getItem("selectedContext")
  );

  // Fetch contexts when component mounts
  useEffect(() => {
    getContexts();
  }, [getContexts]);

  const handleContextSelect = (contextId) => {
    if (contextId === "default") {
      localStorage.removeItem("selectedContext");
      setSelectedContext(null);
    } else {
      localStorage.setItem("selectedContext", contextId);
      setSelectedContext(contextId);
    }

    // Oppdater siden etter at en ny kontekst er valgt
    window.location.reload();
  };

  const confirmLogout = () => {
    logout(instance);
    toggleModal();
  };

  const handleNavClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Navbar
        color="dark"
        dark
        expand="lg"
        className="shadow-sm position-sticky fixed-top"
        style={{ zIndex: 1080 }}
      >
        {/* KONGLA title or dropdown depending on authentication */}
        {isAuthenticated ? (
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} nav inNavbar>
            <DropdownToggle nav caret>
              KONGLA{" "}
              {selectedContext
                ? `(${(() => {
                    const context = contexts.find(
                      (c) => c.id === selectedContext
                    );
                    return context?.name?.length > 20
                      ? `${context.name.slice(0, 20)}...`
                      : context?.name || "";
                  })()})`
                : ""}
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem
                onClick={() => handleContextSelect("default")}
                active={!selectedContext}
                style={{
                  backgroundColor: !selectedContext ? "#007bff" : "",
                  color: !selectedContext ? "#ffffff" : "",
                }}
              >
                {t("default")}
              </DropdownItem>
              {contexts.length > 0 ? (
                contexts.map((context) => (
                  <DropdownItem
                    key={context.id}
                    onClick={() => handleContextSelect(context.id)}
                    active={context.id === selectedContext}
                    style={{
                      backgroundColor:
                        context.id === selectedContext ? "#007bff" : "",
                      color: context.id === selectedContext ? "#ffffff" : "",
                    }}
                  >
                    {context.name.length > 20
                      ? `${context.name.substring(0, 20)}...`
                      : context.name}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem disabled>
                  {t("no_contexts_available")}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <NavbarBrand href="/">KONGLA</NavbarBrand>
        )}

        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </NavbarToggler>
        <Collapse className="justify-content-between " isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavBarItem
              to="/"
              onClick={handleNavClick}
              icon={faHome}
              label={t("home")}
            />
            {isAuthenticated ? (
              <>
                <NavBarItem
                  to="/dialog"
                  onClick={handleNavClick}
                  icon={faComments}
                  label={t("dialog")}
                />
                <NavBarItem
                  to="/content"
                  onClick={handleNavClick}
                  icon={faBook}
                  label={t("content")}
                />
                <NavBarItem
                  to="/investigate"
                  onClick={handleNavClick}
                  icon={faSearch}
                  label={t("investigate")}
                />
                <NavBarItem
                  to="/context"
                  onClick={handleNavClick}
                  icon={faCogs}
                  label={t("context")}
                />
                <NavBarItem
                  to="/classify"
                  onClick={handleNavClick}
                  icon={faTags}
                  label={t("classify")}
                />
                <NavBarItem
                  to="/profile"
                  onClick={handleNavClick}
                  icon={faUser}
                  label={t("profile")}
                />
              </>
            ) : (
              <NavBarItem
                to="/login"
                onClick={handleNavClick}
                icon={faSignInAlt}
                label={t("login")}
              />
            )}
          </Nav>
          <div className="d-flex align-items-center">
            <div className="me-2">
              <LanguageSelector onClick={handleNavClick}  />
            </div>
            <div>
              <ThemeSwitcher onClick={handleNavClick}  />
            </div>
          </div>
        </Collapse>
      </Navbar>
      <LogoutModal
        isOpen={modal}
        toggle={toggleModal}
        onConfirm={confirmLogout}
      />
    </>
  );
};

export default NavBar;
