import React, { useState } from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip} from "reactstrap";
import "./Button.css";
import { FaFilePdf, FaQuestionCircle } from "react-icons/fa";

export const PrimaryButton = ({ type, color, onClick, icon: Icon, label, disabled, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="button-wrapper">
      <Button
        id="primaryButton"
        type={type}
        color={color}
        onClick={onClick}
        className="primary-btn"
        disabled={disabled}
      >
        {Icon && <Icon className="icon" />} {label}
      </Button>
      {tooltipText && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="primaryButton"
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

export const SecondaryButton = ({ type, color, onClick, icon: Icon, label, disabled, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="button-wrapper">
      <Button
        id="secondaryButton"
        type={type}
        color={color}
        onClick={onClick}
        className="secondary-btn"
        disabled={disabled}
      >
        {Icon && <Icon className="icon" />} {label}
      </Button>
      {tooltipText && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="secondaryButton"
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

export const CancelButton = ({ type, color, onClick, icon: Icon, label, disabled, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="button-wrapper">
      <Button
        id="cancelButton"
        type={type}
        color={color}
        onClick={onClick}
        className="cancel-btn"
        disabled={disabled}
      >
        {Icon && <Icon className="icon" />} {label}
      </Button>
      {tooltipText && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="cancelButton"
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

export const OptionButton = ({ type, borderColor, textColor, iconColor, onClick, icon: Icon, label, disabled, active, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="button-wrapper">
      <Button
        id="optionButton"
        type={type}
        style={{ borderColor: borderColor, color: textColor, borderWidth: "1px", borderStyle: "solid" }}
        onClick={onClick}
        disabled={disabled}
        className={`option-btn ${active ? "active-btn" : ""}`}
      >
        {Icon && <Icon className="icon" style={{ color: iconColor }} />} {label}
      </Button>
      {tooltipText && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="optionButton"
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

export const ExportButton = ({ onExportPDF }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="export-dropdown">
            <DropdownToggle caret color="primary" className="export-btn">
                Export
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={onExportPDF}>
                    <FaFilePdf className="me-2" /> Export to PDF
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};





export const QuestionButton = ({ onClick, label = "How it works", tooltipText }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <div className="button-wrapper">
            <Button
                id="questionButton"
                color="info"
                onClick={onClick}
                className="question-btn"
            >
                <FaQuestionCircle className="icon" /> {label}
            </Button>
            {tooltipText && (
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="questionButton"
                    toggle={toggleTooltip}
                >
                    {tooltipText}
                </Tooltip>
            )}
        </div>
    );
};

export default PrimaryButton;
